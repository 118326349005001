<template>
  <input
    data-iterator="fill"
    v-model.trim="value"
    @input="updateValueField"
    class="esignature-value-field sign-iterator"
    type="text"
    :placeholder="label == 'name' ? 'Name' : 'Location'"
  />
</template>

<script>
export default {
  name: "NamePicker",
  props: {
    signInfo: Object,
    id: String,
    label: String
  },
  data() {
    return {
      value: ""
    };
  },
  computed: {
    idNum() {
      return parseInt(this.id);
    }
  },
  methods: {
    updateValueField() {
      if (this.value.length > 2) {
        this.$parent.$parent.$emit("onSign", {
          id: this.idNum,
          signed: true
        });
      } else {
        this.$parent.$parent.$emit("onSign", {
          id: this.idNum,
          signed: false
        });
      }
      const updatedSignInfo = { ...this.signInfo };
      updatedSignInfo[this.label] = this.value;
      this.$parent.$parent.$emit("onSignInfoUpdate", updatedSignInfo);
    }
  }
};
</script>

<style scoped>
.esignature-value-field {
  border: 1px solid black;
  height: 20px;
  width: 200px;
  font: caption;
}
</style>