<template>
  <div class="canvas-sign-container">
    <div class="signature-drawing-panel">
      <VueSignaturePad
        width="100%"
        ref="signaturePad"
        class="mx-auto my-6"
        :options="{ onBegin, onEnd }"
      />
    </div>
    <v-divider></v-divider>
    <v-card-actions class="py-4 px-0">
      <v-btn depressed @click="onCancelClick">
        <i18n path="Common.Cancel" />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="disableClear" depressed @click="onClearClick">
        <i18n path="Common.Clear" />
      </v-btn>
      <v-btn depressed :disabled="signButtonIsDisabled" color="primary" @click="onSignClick">
        <i18n path="Common.Save" />
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "CanvasSign", 
  props: ["type", "value"],
  data() {
    return {
      disableClear: true,
      signButtonIsDisabled: true,
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.prepareCanvas);
      this.prepareCanvas();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.prepareCanvas); 
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onBegin() {
      this.disableClear = false;
      this.signButtonIsDisabled = false;
    },
    onEnd() {
      this.$emit('onUpdateTab');
    },
    onSignClick() {
      this.disableClear = true;
      this.signButtonIsDisabled = true;
      const sign = this.$refs.signaturePad.saveSignature();
      this.$emit('onSave', sign);
    },
    onCancelClick() {
      this.disableClear = true;
      this.signButtonIsDisabled = true;
      this.$emit('onCancel');
    },
    onClearClick() {
      this.disableClear = true;
      this.signButtonIsDisabled = true;
      this.prepareCanvas();
    },
    prepareCanvas() {
      this.$refs.signaturePad.clearSignature();
      const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
      const context = canvas.getContext('2d');
      canvas.width = canvas.getBoundingClientRect().width;
      canvas.height = canvas.width/4.72222;
      this.$refs.signaturePad.clearSignature();
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.closePath();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.signature-drawing-panel {
  position: relative;
  z-index: 2;
}
.signature-drawing-panel >>> canvas {
  border: 1px solid black;
  margin: 1em 0;
  width: 100%;
  touch-action: none;
}
.clear-sign-button {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 3;
}
.v-card__actions {
  padding: 0;
}
@media (max-width: 600px) {
  .signature-drawing-panel {
    min-height: 160px;
  }
}
</style>
