var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"esignature-stamp-field sign-iterator",attrs:{"data-iterator":"fill"}},[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","content-class":"slide-form","max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"overflow-hidden pa-0"},[_c('v-card-title',{staticClass:"pt-1"},[_c('h2',{staticClass:"black--text pt-4 pb-2 text-h5"},[_c('i18n',{attrs:{"path":"Common.SubmitStamp"}})],1)]),_c('v-card-text',{staticClass:"px-6"},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"body-1 py-2 px-0 grey--text text--darken-2",attrs:{"cols":"12"}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.inputFileChange}}),(!_vm.stampImage)?_c('v-card',{class:{
                'grey lighten-2': _vm.dragover,
                'py-2 esignature-stamp-upload': true,
              },on:{"click":function($event){!_vm.stampImage && _vm.$refs.file.click()},"drop":function($event){$event.preventDefault();return _vm.onFileDrop($event)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false}}},[_c('v-card-text',[_c('v-row',{staticClass:"d-flex flex-column",attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-icon',{class:[_vm.dragover ? 'mt-2, mb-6' : 'mt-5'],attrs:{"size":"60"}},[_vm._v(" mdi-cloud-upload ")]),_c('p',[_c('i18n',{attrs:{"path":"Common.DropImageFile"}})],1)],1)],1)],1):_c('v-row',[_c('v-chip',{staticClass:"ma-2 esignature-stamp-upload-selected",attrs:{"outlined":"","large":"","close":""},on:{"click:close":_vm.clearDocument}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-file")])],1),_vm._v(" "+_vm._s(_vm.stampImage.name)+" "),_c('span',{staticClass:"ml-3 text--secondary"},[_vm._v(" "+_vm._s(_vm.formatBytes(_vm.stampImage.size)))])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-4 px-0"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.onCancelClick}},[_c('i18n',{attrs:{"path":"Common.Cancel"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","disabled":_vm.disableStampButton,"color":"primary"},on:{"click":_vm.onUploadClick}},[_c('i18n',{attrs:{"path":"Common.SubmitStamp"}})],1)],1)],1)],1)],1),(_vm.signInfo.signStamp)?_c('v-menu',{attrs:{"absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var onTooltip = ref.on;
              var attrsTooltip = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"stamp-display-panel",attrs:{"src":_vm.signInfo.signStamp}},'img',Object.assign({}, attrs, attrsTooltip),false),Object.assign({}, on, onTooltip)))]}}],null,true)},[_c('span',[_c('i18n',{attrs:{"path":"Common.ClickToChange"}})],1)])]}}],null,false,1669881234)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onClearClick}},[_c('v-list-item-title',{staticClass:"red--text"},[_c('i18n',{attrs:{"path":"Common.Clear"}})],1)],1)],1)],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"36px","width":"170px","elevation":"0","color":"red lighten-4"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('i18n',{attrs:{"path":"Common.StampHere"}}),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_c('i18n',{attrs:{"path":"Common.ClickToStamp"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }