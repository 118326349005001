<template>
  <div id="app">
    <v-dialog v-model="declineSignConfirmDialog" persistent max-width="600px">
      <v-sheet>
        <v-card-title
          class="pl-md-10 pl-lg-10 pl-xl-15 pr-1 pb-0 pt-1 d-flex align-start"
        >
          <h2 class="black--text pt-5 pb-4 font-weight-regular text-h5">
            <i18n path="Common.DeclineRequest" />
          </h2>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="declineSignConfirmDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="px-xs-2 px-md-10 px-lg-10 px-xl-15 pt-0">
          <v-row>
            <v-col cols="12">
              <i18n path="Common.DeclineConfirmationMessage" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          class="px-xs-3 px-md-10 px-lg-10 px-xl-15 px-xs-10 pl-xs-10"
        >
          <v-btn class="sendButtons" depressed @click="onDeclineDocument"
            ><i18n path="Common.Yes"
          /></v-btn>
          <v-btn
            color="primary"
            depressed
            class="sendButtons"
            @click="declineSignConfirmDialog = false"
            ><i18n path="Common.No"
          /></v-btn>
        </v-card-actions>
      </v-sheet>
    </v-dialog>
    <v-app>
      <v-snackbar v-model="snackbar" :top="true" :timeout="3000">
        <div class="px-2">
          {{ snackbarText }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <Header
        v-if="!displayFinishPage"
        :scrollButtonText="scrollButtonText"
        :disableScroll="disableScroll"
        :initialDisplay="initialDisplay"
        :imgLogoSrc="imgLogoSrc"
        @scrollToSign="scrollToSign"
        @declineSignClick="declineSignClick"
      />
      <FinishPage
        class="main-body"
        v-if="displayFinishPage"
        :status="recipientStatus"
        :imgSrc="imgSrc"
        :alreadySigned="finishPageAlreadySigned"
        :finishPageLoading="finishPageLoading"
        :requestExpired="requestExpired"
      />
      <v-main class="main-body" v-else>
        <v-skeleton-loader
          v-if="initialDisplay"
          class="page google-docs-page"
          type="table-heading, paragraph@8"
        ></v-skeleton-loader>
        <component
          v-else
          :is="eSignTemplate"
          v-bind="{ signInfo: signInfo, documentTemplate: documentTemplate }"
          @onDocumentTemplateUpdate="onDocumentTemplateUpdate"
          @onErrorDisplay="onErrorDisplay"
          @onSign="onSign"
          @onSignInfoUpdate="onSignInfoUpdate"
          @onClear="onClear"
        />
        <v-btn
          color="green accent-3"
          :class="[
            disableScroll ? 'btn-caret-disabled' : 'btn-caret',
            'white--text',
          ]"
          id="scroll-btn"
          v-if="!$vuetify.breakpoint.smAndDown && !initialDisplay"
          fixed
          left
          :disabled="disableScroll"
          width="100px"
          top
          :style="{ top: buttonTop, 'margin-left': buttonLeft }"
          @click="scrollToSign"
        >
          <i18n v-if="scrollButtonText === 'start'" path="Common.Start" />
          <i18n v-else-if="scrollButtonText === 'next'" path="Common.Next" />
          <i18n v-else-if="scrollButtonText === 'sign'" path="Common.Sign" />
          <i18n v-else-if="scrollButtonText === 'fill'" path="Common.Fill" />
          <i18n v-else path="Common.Finish" />
        </v-btn>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import Handlebars from "handlebars";
import ESignUpload from "./components/ESignUpload.vue";
import ESignField from "./components/ESignField.vue";
import ESignStampPicker from "./components/ESignStampPicker.vue";
import FinishPage from "./components/FinishPage.vue";
import Header from "./components/Header.vue";

const signTagRegExp = /{{{ESign\.(\w+)\.?([\w]*)}}}/g;

const styleTagGoogleDocsRegExp = /<style[^>]*>([\s\S]*)<\/style>/g;

export default {
  name: "App",
  components: {
    FinishPage,
    Header
  },
  data() {
    return {
      eSignTemplate: {
        data: function() {
          return {
            signCount: 0
          };
        },
        props: { signInfo: Object, documentTemplate: Object },
        render(h) {
          const res = Vue.compile(this.documentTemplate.template);
          res.components = {
            ESignPicker: ESignUpload,
            ESignField: ESignField,
            StampPicker: ESignStampPicker
          };
          res.props = ["signCount", "signInfo"];
          res.mounted = function() {
            const signObject = [];
            for (let itr = 0; itr < this.signCount; itr++) {
              signObject.push({ signed: false });
            }
            const initials = this.signInfo.name
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), "");
            this.$parent.$emit("onSignInfoUpdate", {
              ...this.signInfo,
              initials: initials,
              sign: signObject
            });
          };
          const signPickerProps = {
            signCount: this.signCount,
            signInfo: this.signInfo
          };
          return h(res, { props: signPickerProps });
        },
        mounted() {
          const renderingStrategy = this.documentTemplate.renderingStrategy;
          let innerHTML;
          switch (renderingStrategy) {
            case "GoogleDocs":
              innerHTML = this.renderGoogleDocsDocument();
              break;
            case "PDF":
              innerHTML = this.renderPdfDocument();
              break;
            default:
              innerHTML = this.renderPdfDocument();
              break;
          }
          this.$emit("onDocumentTemplateUpdate", {
            ...this.documentTemplate,
            template: innerHTML
          });
        },
        methods: {
          renderGoogleDocsDocument() {
            let innerHTML =
              '<div><div class="page google-docs-page"><div class="sign-iterator" data-iterator="start"></div>';
            this.signCount = 0;
            const styling = document.createElement("style");
            styling.type = "text/css";
            styling.appendChild(
              document.createTextNode(this.documentTemplate.style)
            );
            document.head.appendChild(styling);
            const documentBody = document.querySelector("#page-container");
            const bodyContainer = documentBody.children;
            for (let item of bodyContainer) {
              let tempHTML = item.outerHTML;
              tempHTML += '</div> <div class="page google-docs-page">';
              tempHTML = this.replaceESignatureTags(tempHTML);
              innerHTML += tempHTML;
            }
            innerHTML += "</div>";
            innerHTML += "</div>";
            innerHTML = innerHTML.replace(
              '<div class="page google-docs-page"></div>',
              ""
            );
            return innerHTML;
          },
          renderPdfDocument() {
            let innerHTML =
              '<div><div class="page pdf-page"><div class="sign-iterator" data-iterator="start"></div>';
            this.signCount = 0;
            const styling = document.createElement("style");
            styling.type = "text/css";
            styling.appendChild(
              document.createTextNode(this.documentTemplate.style)
            );
            document.head.appendChild(styling);
            const documentBody = document.querySelector(
              ".v-main__wrap > html > body > #page-container"
            );
            const bodyContainer = documentBody.children;
            for (let item of bodyContainer) {
              const signatureElementsToBeReplaced = Array.from(
                item.querySelectorAll(".signature-tag-container")
              )
                .sort((item1, item2) => {
                  return item1.getBoundingClientRect().top >
                    item2.getBoundingClientRect().top
                    ? 1
                    : -1;
                })
                .map(item => item.outerHTML);
              const updatedSignatureTagElements = [];
              signatureElementsToBeReplaced.forEach(item => {
                const updatedSignatureTagElement = this.replaceESignatureTags(
                  item
                );
                updatedSignatureTagElements.push(updatedSignatureTagElement);
              });
              let tempHTML = item.outerHTML;
              for (
                let i = 0;
                i < updatedSignatureTagElements.length &&
                i < signatureElementsToBeReplaced.length;
                i++
              ) {
                tempHTML = tempHTML.replace(
                  signatureElementsToBeReplaced[i],
                  updatedSignatureTagElements[i]
                );
              }
              tempHTML = this.replaceESignatureTags(tempHTML);
              innerHTML += tempHTML;
              innerHTML += '</div> <div class="page pdf-page">';
            }
            innerHTML += "</div>";
            innerHTML += "</div>";
            innerHTML = innerHTML.replace(
              '<div class="page pdf-page"></div>',
              ""
            );
            return innerHTML;
          },
          replaceESignatureTags(htmlText) {
            htmlText = htmlText.replaceAll(
              'NamePicker v-model="signInfo"',
              () =>
                `<ESignField id="${this
                  .signCount++}" :signInfo="this.signInfo" label="name" />`
            );
            htmlText = htmlText.replaceAll(
              'LocationPicker v-model="signInfo"',
              () =>
                `<ESignField id="${this
                  .signCount++}" :signInfo="this.signInfo" label="signLocation" />`
            );
            htmlText = htmlText.replaceAll(
              'StampPicker v-model="signInfo"',
              () =>
                `<StampPicker id="${this
                  .signCount++}" :signInfo="this.signInfo" />`
            );
            htmlText = htmlText.replaceAll(
              'ESignPicker v-model="signInfo" type="initials"',
              () =>
                `<ESignPicker id="${this
                  .signCount++}" :signInfo="this.signInfo" type="initials" />`
            );
            htmlText = htmlText.replaceAll(
              'ESignPicker v-model="signInfo"',
              () =>
                `<ESignPicker id="${this
                  .signCount++}" :signInfo="this.signInfo" />`
            );
            return htmlText;
          }
        }
      },
      scrollElementID: 0,
      scrollButtonText: "start",
      disableScroll: false,
      buttonLeft: 0,
      buttonTop: 0,
      isSigned: [],
      documentTemplate: {
        template: "",
        style: "",
        renderingStrategy: ""
      },
      signInfo: {
        name: "",
        email: "",
        initials: "",
        defaultSign: "",
        defaultInitials: "",
        dateSigned: "",
        signLocation: "",
        signStamp: "",
        sign: [],
        declineSign: false,
        recipient: {},
        request: {},
        tagType: []
      },
      imgLogoSrc: "https://login.bitpod.io/auth/Content/BitPod-Logo.png",
      displayFinishPage: false,
      recipientStatus: "",
      imgSrc: undefined,
      initialDisplay: true,
      initialButtonLoad: true,
      elementArray: [],
      handlebarsData: {},
      isScrolling: false,
      scrollStopTimer: 0,
      lastPosition: 0,
      finishPageAlreadySigned: false,
      finishPageLoading: true,
      declineSignConfirmDialog: false,
      requestExpired: false,
      snackbar: false,
      snackbarText: ""
    };
  },
  updated() {
    if (this.initialDisplay === false && this.initialButtonLoad) {
      this.calculateLeftPosition();
      this.calculateTopPosition();
      this.initialButtonLoad = false;
    }
  },
  beforeCreate() {
    window.addEventListener("message", event => {
      if (event.data && event.data.type === "signInfo") {
        const data = event.data;
        this.onMessage(data);
      }
      if (event.data && event.data.type === "responseInfo") {
        const data = event.data;
        this.onResponse(data);
      }
    });
  },
  created() {
    window.addEventListener("resize", this.calculateLeftPosition);
    window.addEventListener("scroll", this.updateNextButtonState);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateLeftPosition);
    window.removeEventListener("scroll", this.updateNextButtonState);
  },
  methods: {
    getDistanceFromTop(element) {
      return window.pageYOffset + element.getBoundingClientRect().top;
    },
    isElementInViewport(element) {
      var rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      );
    },
    generateIsSigned() {
      if (this.isSigned.length == 0) {
        const signIteratorElements = this.$el.getElementsByClassName(
          "sign-iterator"
        );
        this.elementArray = Array.from(signIteratorElements).sort(
          (elem1, elem2) =>
            this.getDistanceFromTop(elem1) - this.getDistanceFromTop(elem2)
        );
        for (let item of this.elementArray) {
          if (item.dataset.iterator === "sign") {
            this.isSigned.push(0);
            this.signInfo.tagType.push(item.dataset.tagType);
          } else if (item.dataset.iterator === "fill") {
            this.isSigned.push(0);
            this.signInfo.tagType.push("stamp");
          } else {
            this.isSigned.push(-1);
            this.signInfo.tagType.push("other");
          }
        }
      }
    },
    scrollToElement(element) {
      if (element) {
        const buttonTop = this.getDistanceFromTop(element);
        const buttonBottom = document.documentElement.scrollHeight - buttonTop;
        this.isScrolling = true;
        if (
          buttonTop < window.innerHeight / 2 &&
          this.isElementInViewport(element)
        ) {
          this.buttonTop = `${buttonTop}px`;
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        } else if (buttonBottom < window.innerHeight / 2) {
          this.buttonTop = `${window.innerHeight - buttonBottom}px`;
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        } else {
          this.buttonTop = `${(window.innerHeight - element.offsetHeight) /
            2}px`;
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        }
      }
    },
    scrollToSign() {
      this.generateIsSigned();
      if (this.scrollButtonText === "finish" && this.disableScroll === false) {
        this.submitDocument();
      } else {
        this.isSigned.some((item, index) => {
          if (index === this.isSigned.length - 1) {
            this.scrollButtonText = "finish";
          }
          if (item === 0) {
            const element = this.elementArray[index];
            this.scrollButtonText = element.dataset.iterator;
            this.scrollToElement(element);
            this.scrollElementID = index;
            this.disableScroll = true;
            return true;
          } else {
            return false;
          }
        });
      }
    },
    updateDocumentTemplate(request, recipient) {
      let documentTemplate = request.DocumentText;
      const allRecipients = request.Recipient;
      const renderingStrategy = request.RenderingStrategy;
      this.documentTemplate.renderingStrategy = renderingStrategy;
      switch (renderingStrategy) {
        case "PDF": {
          documentTemplate = this.renderPdf(
            recipient,
            allRecipients,
            documentTemplate
          );
          break;
        }
        case "GoogleDocs": {
          documentTemplate = this.renderGoogleDocs(
            recipient,
            allRecipients,
            documentTemplate
          );
          break;
        }
        default: {
          documentTemplate = this.renderGoogleDocs(
            recipient,
            allRecipients,
            documentTemplate
          );
          break;
        }
      }
      try {
        const handlebarsData = request.TemplateData;
        const handlebarsTemplate = Handlebars.compile(documentTemplate);
        this.handlebarsData = handlebarsData;
        documentTemplate = handlebarsTemplate(handlebarsData);
      } catch (err) {
        console.error(
          "Error encountered in App.vue while attempting to parse JSON data: ",
          err
        );
      }
      this.setSignInfo(request, recipient, allRecipients, documentTemplate);
    },
    replaceActionTags(documentTemplate, type) {
      const recipientTypeInitialsRegExp = new RegExp(
        "{{{ESign\\." + type + ".Initials}}}",
        "g"
      );
      documentTemplate = documentTemplate.replaceAll(
        recipientTypeInitialsRegExp,
        () => `ESignPicker v-model="signInfo" type="initials"`
      );
      const recipientTypeDateRegExp = new RegExp(
        "{{{ESign\\." + type + ".Date}}}",
        "g"
      );
      documentTemplate = documentTemplate.replaceAll(
        recipientTypeDateRegExp,
        () => `${new Date().toDateString()}`
      );
      const recipientTypePlaceRegExp = new RegExp(
        "{{{ESign\\." + type + ".Name}}}",
        "g"
      );
      documentTemplate = documentTemplate.replaceAll(
        recipientTypePlaceRegExp,
        () => `NamePicker v-model="signInfo"`
      );
      const recipientTypeLocationRegExp = new RegExp(
        "{{{ESign\\." + type + ".Location}}}",
        "g"
      );
      documentTemplate = documentTemplate.replaceAll(
        recipientTypeLocationRegExp,
        () => `LocationPicker v-model="signInfo"`
      );
      const recipientTypeStampRegExp = new RegExp(
        "{{{ESign\\." + type + ".Stamp}}}",
        "g"
      );
      documentTemplate = documentTemplate.replaceAll(
        recipientTypeStampRegExp,
        () => `StampPicker v-model="signInfo"`
      );
      const recipientTypeRegExp = new RegExp(
        "{{{ESign\\." + type + "[\\.[\\w]*]?}}}",
        "g"
      );
      documentTemplate = documentTemplate.replaceAll(
        recipientTypeRegExp,
        () => `ESignPicker v-model="signInfo"`
      );
      return documentTemplate;
    },
    renderPdf(recipient, allRecipients, documentTemplate) {
      const documentTemplateDiv = document.createElement("html");
      documentTemplateDiv.innerHTML = documentTemplate;
      const styleTagElements = documentTemplateDiv.getElementsByTagName(
        "style"
      );
      let styleInnerHtml;
      while (styleTagElements[0]) {
        styleInnerHtml += styleTagElements[0].innerHTML;
        styleTagElements[0].parentNode.removeChild(styleTagElements[0]);
      }
      this.documentTemplate.style = styleInnerHtml;

      documentTemplate = this.replaceActionTags(
        documentTemplateDiv.outerHTML,
        recipient.type
      );

      let signRegex;
      while ((signRegex = signTagRegExp.exec(documentTemplate)) !== null) {
        if (signRegex[2] && signRegex[2] === "Party")
          documentTemplate = documentTemplate.replace(
            signRegex[0],
            signRegex[1]
          );
        else if (signRegex[2] && signRegex[2] === "Signature")
          documentTemplate = documentTemplate.replace(
            signRegex[0],
            '<div style="height: 36px; width: 170px; border-bottom: solid 1px black; display: inline-flex"></div>'
          );
        else if (signRegex[2] && signRegex[2] === "Name") {
          for (let item of allRecipients) {
            if (item.type === signRegex[1])
              documentTemplate = documentTemplate.replace(
                signRegex[0],
                item.FullName
              );
          }
        } else
          documentTemplate = documentTemplate.replace(
            signRegex[0],
            '<div style="height: 36px; width: 170px; border-bottom: solid 1px black; display: inline-flex"></div>'
          );
      }
      documentTemplate = documentTemplate.replaceAll("{{", "");
      documentTemplate = documentTemplate.replaceAll("}}", "");
      return documentTemplate;
    },
    renderGoogleDocs(recipient, allRecipients, documentTemplate) {
      const styleRegex = styleTagGoogleDocsRegExp.exec(documentTemplate);
      if (styleRegex) {
        const styleElem = styleRegex[1];
        this.documentTemplate.style = styleElem;
        documentTemplate = this.replaceActionTags(
          documentTemplate.replace(styleRegex[0], ""),
          recipient.type
        );
      }

      let signRegex;
      while ((signRegex = signTagRegExp.exec(documentTemplate)) !== null) {
        if (signRegex[2] && signRegex[2] === "Party")
          documentTemplate = documentTemplate.replace(
            signRegex[0],
            signRegex[1]
          );
        else if (signRegex[2] && signRegex[2] === "Signature")
          documentTemplate = documentTemplate.replace(
            signRegex[0],
            '<div style="height: 36px; width: 170px; border-bottom: solid 1px black; display: inline-flex"></div>'
          );
        else if (signRegex[2] && signRegex[2] === "Name") {
          for (let item of allRecipients) {
            if (item.type === signRegex[1])
              documentTemplate = documentTemplate.replace(
                signRegex[0],
                item.FullName
              );
          }
        } else
          documentTemplate = documentTemplate.replace(
            signRegex[0],
            '<div style="height: 36px; width: 170px; border-bottom: solid 1px black; display: inline-flex"></div>'
          );
      }
      return documentTemplate;
    },
    setSignInfo(request, recipient, allRecipients, documentTemplate) {
      if (
        recipient.esignstore.SignatureImage &&
        recipient.esignstore.SignatureImage.length > 0
      )
        this.signInfo.defaultSign = recipient.esignstore.SignatureImage;
      if (
        recipient.esignstore.InitialsImage &&
        recipient.esignstore.InitialsImage.length > 0
      )
        this.signInfo.defaultInitials = recipient.esignstore.InitialsImage;
      this.signInfo.name = recipient.FullName;
      this.signInfo.email = recipient.Email;
      this.signInfo.recipient = recipient;
      this.signInfo.request = request;
      this.signInfo.allRecipients = allRecipients;
      this.documentTemplate.template = documentTemplate;
    },
    submitDocument() {
      parent.postMessage(
        {
          ...this.signInfo,
          type: "signInfo",
          template: this.documentTemplate.template,
          handlebarsData: this.handlebarsData
        },
        "*"
      );
      this.displayFinishPage = true;
      this.recipientStatus = "Signed";
    },
    onMessage(data) {
      if (data.recipient && data.recipient.Status === "Signed") {
        this.displayFinishPage = true;
        this.finishPageLoading = false;
        this.finishPageAlreadySigned = true;
        this.recipientStatus = data.recipient.Status;
        return;
      }
      if (data.recipient && data.recipient.Status === "Declined") {
        this.displayFinishPage = true;
        this.finishPageLoading = false;
        this.finishPageAlreadySigned = true;
        this.recipientStatus = data.recipient.Status;
        return;
      }
      if (
        data.request &&
        new Date(data.request.ExpirationDate).getTime() <
          new Date().setHours(0, 0, 0, 0)
      ) {
        this.displayFinishPage = true;
        this.requestExpired = true;
        this.finishPageLoading = false;
        this.finishPageAlreadySigned = true;
        this.recipientStatus = data.recipient.Status;
        return;
      }
      this.updateDocumentTemplate(data.request, data.recipient);
      this.calculateTopPosition();
      this.initialDisplay = false;
    },
    onResponse(data) {
      if (data && data[1] && data[1].requestProcessed === false) {
        if (data[1].message.includes("signed")) {
          this.finishPageAlreadySigned = true;
          this.recipientStatus = "Signed";
        } else {
          this.finishPageAlreadySigned = true;
          this.recipientStatus = "Declined";
        }
      }
      this.finishPageLoading = false;
    },
    onClear(e) {
      const updatedIsSigned = [];
      const updatedSigned = [];
      for (let iter = 0; iter < this.isSigned.length; iter += 1) {
        if (this.signInfo.tagType[iter] === e.type) {
          updatedIsSigned.push(0);
          updatedSigned.push({signed: false})
        } else {
          updatedIsSigned.push(this.isSigned[iter]);
          if (this.signInfo.tagType[iter] !== "other") updatedSigned.push(this.signInfo.sign[updatedSigned.length])
        }
      }
      if (e.type === "initials") {
        this.signInfo.defaultInitials = "";
      } else {
        this.signInfo.defaultSign = "";
      }
      this.isSigned = updatedIsSigned;
      this.signInfo.sign = updatedSigned;
    },
    onSign(e) {
      this.generateIsSigned();
      let iter = e.id;
      for (let item of this.isSigned) {
        if (item === -1) iter += 1;
      }
      const value = e.signed ? 1 : 0;
      Vue.set(this.isSigned, iter, value);
    },
    calculateLeftPosition() {
      if (this.displayFinishPage === false) {
        const page = document.querySelector(".page");
        const buttonWidth = 100;
        const left = page.getBoundingClientRect().left - 16 - buttonWidth;
        const leftDistance =
          window.pageXOffset + page.getBoundingClientRect().left;
        this.buttonLeft = "0px";
        if (left > 0) {
          this.buttonLeft = `${left}px`;
        } else if (leftDistance > 0) {
          this.buttonLeft = `${leftDistance}px`;
        }
      }
    },
    calculateTopPosition() {
      const pageTop = this.getDistanceFromTop(document.querySelector(".page"));
      this.buttonTop = `${pageTop}px`;
    },
    onSignInfoUpdate(e) {
      this.signInfo = e;
    },
    onDocumentTemplateUpdate(e) {
      this.documentTemplate = e;
    },
    onDeclineDocument() {
      this.declineSignConfirmDialog = false;
      this.signInfo.declineSign = true;
      this.displayFinishPage = true;
      this.recipientStatus = "Declined";
      parent.postMessage(
        {
          ...this.signInfo,
          type: "signInfo",
          template: this.documentTemplate.template,
          handlebarsData: this.handlebarsData
        },
        "*"
      );
    },
    updateNextButtonState() {
      clearTimeout(this.scrollStopTimer);
      this.scrollStopTimer = setTimeout(() => {
        const currentPosition = window.pageYOffset;
        if (
          !this.isScrolling &&
          (this.scrollButtonText === "sign" ||
            this.scrollButtonText === "fill") &&
          this.disableScroll === true &&
          Math.abs(currentPosition - this.lastPosition) > 50
        ) {
          this.scrollElementID -= 1;
          this.scrollButtonText = "next";
        }
        if (this.isScrolling) this.lastPosition = currentPosition;
        this.isScrolling = false;
      }, 200);
    },
    declineSignClick() {
      this.declineSignConfirmDialog = true;
    },
    onErrorDisplay(errorString) {
      this.snackbar = true;
      this.snackbarText = errorString;
    }
  },
  watch: {
    isSigned() {
      if (
        this.isSigned[this.scrollElementID] === 1 ||
        this.isSigned[this.scrollElementID] === -1
      ) {
        this.disableScroll = false;
        const allElementsSigned = this.isSigned.every(item => item !== 0);
        if (allElementsSigned) this.scrollButtonText = "finish";
        else this.scrollButtonText = "next";
      } else if (
        this.isSigned[this.scrollElementID] === 0 &&
        this.scrollButtonText === "finish"
      ) {
        this.scrollButtonText = "next";
      }
    },
    scrollElementID() {
      if (this.isSigned[this.scrollElementID] === 0) this.disableScroll = true;
      else this.disableScroll = false;
    },
    scrollButtonText() {
      this.calculateLeftPosition();
    }
  }
};
</script>

<style>
body {
  overflow-x: auto;
  overflow-y: hidden;
}

div::after {
  content: none !important;
}

div::before {
  content: none !important;
}

.v-application p {
  margin-bottom: unset;
}

.btn-caret:hover::after {
  border-left-color: #00d76e;
}

#scroll-btn {
  transition: top 0.5s ease 0s;
}

#scroll-btn.v-btn--disabled {
  background-color: #b9f6ca !important;
}

.btn-caret,
.btn-caret-disabled {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-caret::after,
.btn-caret-disabled::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -18px;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 1em solid;
  right: -1em;
  border-left-color: #00e676;
  box-shadow: none;
}

.btn-caret-disabled::after {
  border-left-color: #b9f6ca;
}

.btn-caret:hover::after {
  opacity: 0.92;
}

.main-body {
  margin-top: 100px;
}

.header {
  background: #005cb9;
  color: white;
  height: 60px;
  margin-bottom: 30px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.page {
  background: white;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin: 2em auto;
  height: auto;
  width: 816px;
}

.google-docs-page {
  display: block;
  position: relative;
  padding: 50px;
}

.google-docs-page-container {
  height: 1056px !important;
}

.pdf-page {
  padding: 0 0;
}

.signature-tag-transform {
  display: inline-block;
}

@page {
  height: 1056px;
  margin: 2em auto;
}

@media print {
  .page {
    page-break-after: always;
    box-shadow: none;
  }

  #scroll-btn {
    display: none;
  }
}
</style>
