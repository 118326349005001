<template>
  <v-main>
    <v-app-bar flat fixed height="50px" color="primary"> </v-app-bar>
    <v-container v-if="!finishPageLoading">
      <div v-if="requestExpired" class="text-center mt-6">
        <v-icon :size="144" color="red" class="mb-6">mdi-alert-circle</v-icon>
        <div class="text-h4 transition-swing">
          <i18n path="Common.RequestExpiredMessage" />
        </div>
      </div>
      <div v-else-if="status === 'Signed'" class="text-center mt-6">
        <div v-if="alreadySigned">
          <v-icon :size="144" color="red" class="mb-6">mdi-alert-circle</v-icon>
        </div>
        <div v-else>
          <v-icon :size="144" color="green" class="mb-6"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </div>
        <div class="text-h4 transition-swing">
          <i18n
            v-if="alreadySigned"
            path="Common.SignedDocumentAlreadyMessage"
          />
          <i18n v-else path="Common.SignedDocumentMessage" />
        </div>
        <div
          v-if="!alreadySigned"
          class="text-h6 text-center ma-6 finish-page-sub-header"
        >
          <i18n path="Common.SignedDocumentWillBeForwarded" />
        </div>
      </div>
      <div v-else class="text-center mt-6">
        <div v-if="alreadySigned">
          <v-icon :size="144" color="red" class="mb-6">mdi-alert-circle</v-icon>
        </div>
        <div v-else>
          <v-icon :size="144" color="green" class="mb-6"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </div>
        <div class="transition-swing text-h4">
          <i18n
            v-if="alreadySigned"
            path="Common.DeclinedDocumentAlreadyMessage"
          />
          <i18n v-else path="Common.DeclinedDocumentMessage" />
        </div>
      </div>
    </v-container>
    <v-container v-else>
      <div class="text-h3 transition-swing text-center mt-6">
        <v-progress-circular
          indeterminate
          :size="72"
          :width="4"
        ></v-progress-circular>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "FinishPage",
  props: {
    imgSrc: {
      type: String,
      required: false,
      default: "https://login.bitpod.io/auth/Content/BitPod-Logo.png"
    },
    status: {
      type: String
    },
    finishPageLoading: {
      type: Boolean
    },
    alreadySigned: {
      type: Boolean
    },
    requestExpired: {
      type: Boolean
    }
  }
};
</script>

<style>
.finish-page-sub-header {
  color: #757575;
}
</style>