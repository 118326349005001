import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { languages, checkDefaultLanguage } from '../locales/index'
const messages = Object.assign(languages)

Vue.use(VueI18n);

export default new VueI18n({
  locale: checkDefaultLanguage(languages),
  fallbackLocale: 'en',
  messages
});