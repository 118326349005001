<template>
  <div
    :class="[
      type === 'initials' ? 'initials-component' : 'signature-component',
      'sign-iterator',
    ]"
    data-iterator="sign"
    :data-tag-type="type === 'initials' ? 'initials' : 'signature'"
  >
    <v-dialog
      persistent
      scrollable
      :key="key"
      content-class="slide-form"
      v-model="dialog"
      max-width="700px"
    >
      <v-card class="overflow-hidden pa-0 esign-form-section">
        <v-card-title class="pt-1">
          <h2 v-if="type === 'initials'" class="black--text pt-4 pb-2 text-h5">
            <i18n path="Common.Initials" />
          </h2>
          <h2 v-else class="black--text pt-4 pb-2 text-h5">
            <i18n path="Common.Signature" />
          </h2>
        </v-card-title>
        <v-row>
          <v-col cols="12" sm="8" md="8" class="pa-6 pb-0 pb-sm-6">
            <v-text-field
              :label="$t('Common.Name')"
              outlined
              v-model="name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="pa-6 pl-6 pl-sm-0 pt-0 pt-sm-6">
            <v-text-field
              :label="$t('Common.Initial')"
              outlined
              v-model="initials"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-tabs class="px-4" v-model="tab">
          <v-tab>
            <i18n v-if="type === 'initials'" path="Common.SelectInitials" />
            <i18n v-else path="Common.SelectSignature" />
          </v-tab>
          <v-tab>
            <i18n path="Common.Draw" />
          </v-tab>
          <v-tab-item>
            <FontSign
              v-if="type === 'initials'"
              :name="initials"
              :type="type"
              @onCancel="onCancel"
              @onSave="onSave"
            />
            <FontSign
              v-else
              :name="name"
              :type="type"
              @onCancel="onCancel"
              @onSave="onSave"
            />
          </v-tab-item>
          <v-tab-item>
            <CanvasSign
              :type="type"
              @onUpdateTab="onUpdateTab"
              @onCancel="onCancel"
              @onSave="onSave"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
    <v-tooltip v-if="signIsEmpty" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          height="36px"
          width="170px"
          @click.stop="onDialogOpen"
          elevation="0"
          color="red lighten-4"
          class="empty-signature-panel mb-1"
          v-bind="attrs"
          v-on="on"
          style="font-family: 'Roboto'"
        >
          <i18n v-if="type === 'initials'" path="Common.InitialsHere" />
          <i18n v-else path="Common.SignHere" />
          <v-icon dense right> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>
        <i18n path="Common.ClickToSign" />
      </span>
    </v-tooltip>
    <v-menu v-else v-model="menu" absolute>
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
            <img
              :src="signInfo.defaultInitials"
              v-bind="{ ...attrs, ...attrsTooltip }"
              v-on="{ ...on, ...onTooltip }"
              v-if="type === 'initials'"
              class="signature-display-panel"
            />
            <img
              :src="signInfo.defaultSign"
              v-bind="{ ...attrs, ...attrsTooltip }"
              v-on="{ ...on, ...onTooltip }"
              v-else
              class="signature-display-panel"
            />
          </template>
          <span>
            <i18n path="Common.ClickToChange" />
          </span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item link @click="onChange">
          <v-list-item-title>
            <i18n path="Common.Change" />
          </v-list-item-title>
        </v-list-item>
        <v-list-item link @click="onClear">
          <v-list-item-title class="red--text">
            <i18n path="Common.Clear" />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import CanvasSign from "./CanvasSign.vue";
import FontSign from "./FontSign.vue";

export default {
  props: {
    signInfo: Object,
    id: String,
    type: String,
  },
  data() {
    return {
      dialog: false,
      menu: false,
      tab: 0,
      name: this.signInfo.name,
      initials: this.signInfo.initials,
      key: this.computeKey(),
    };
  },
  components: {
    CanvasSign,
    FontSign,
  },
  watch: {
    name(newVal) {
      this.initials = newVal
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
    signInfo(newVal) {
      this.name = newVal.name;
      this.initials = newVal.initials;
    },
  },
  computed: {
    idNum() {
      return parseInt(this.id);
    },
    signIsEmpty() {
      if (
        this.signInfo.sign[this.idNum] &&
        this.signInfo.sign[this.idNum].signed == true
      )
        return false;
      return true;
    },
    isInitialsEmpty() {
      if (
        this.value.sign[this.idNum] &&
        this.value.sign[this.idNum].data !== ""
      )
        return false;
      return true;
    },
  },
  methods: {
    computeKey() {
      return Math.round(Math.random() * 10000);
    },
    onSave(e) {
      this.dialog = false;
      const updatedSign = this.signInfo.sign.map((item, index) =>
        index === this.idNum ? { signed: true } : item
      );
      this.$parent.$parent.$emit("onSign", {
        id: this.idNum,
        signed: true,
      });
      if (this.type === "initials") {
        this.$parent.$parent.$emit("onSignInfoUpdate", {
          ...this.signInfo,
          name: this.name,
          initials: this.initials,
          sign: updatedSign,
          defaultInitials: e.data,
        });
      } else {
        this.$parent.$parent.$emit("onSignInfoUpdate", {
          ...this.signInfo,
          name: this.name,
          initials: this.initials,
          sign: updatedSign,
          defaultSign: e.data,
        });
      }
    },
    onCancel() {
      this.dialog = false;
    },
    onUpdateTab() {
      setTimeout(() => {
        this.tab = 1;
      }, 1);
    },
    onChange() {
      this.tab = 0;
      this.key = this.computeKey();
      this.dialog = true;
      this.menu = false;
    },
    onClear() {
      this.$parent.$parent.$emit("onClear", {
        type: this.type === "initials" ? "initials" : "signature",
      });
      this.menu = false;
      this.name = "";
      this.initials = "";
    },
    onDialogOpen() {
      this.tab = 0;
      this.key = this.computeKey();
      if (
        (this.type === "initials" && this.signInfo.defaultInitials === "") ||
        (this.type !== "initials" && this.signInfo.defaultSign === "")
      )
        this.dialog = true;
      else {
        const updatedSign = this.signInfo.sign.map((item, index) =>
          index === this.idNum ? { signed: true } : item
        );
        this.$parent.$parent.$emit("onSign", {
          id: this.idNum,
          signed: true,
        });
        this.$parent.$parent.$emit("onSignInfoUpdate", {
          ...this.signInfo,
          sign: updatedSign,
        });
      }
    },
  },
};
</script>

<style>
.v-dialog__container {
  display: unset !important;
}

.v-application .red.lighten-4 {
  border: 1px dotted #000000 !important;
}

.empty-signature-panel {
  cursor: pointer;
}

.signature-display-panel {
  height: 36px;
}

.signature-component {
  display: inline-block;
}

.initials-component{
  display: inline;
}
@media (max-width: 600px) {
  .esign-form-section {
    max-width: 100%;
  }
}
</style>
