import Vue from 'vue'
import App from './App.vue'
import VueSignaturePad from 'vue-signature-pad';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

Vue.use(VueSignaturePad);
Vue.config.productionTip = false

new Vue({
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
