<template>
  <div class="font-sign-container">
    <v-carousel
      height="150"
      class="test"
      hide-delimiter-background
      :hide-delimiters="delimiters"
      @mouseenter.native="displayDelimiters"
      @mouseleave.native="hideDelimiters"
      v-model="model"
      light
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn icon id="carousel-arrow">
          <v-icon v-bind="attrs" v-on="on">mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn icon id="carousel-arrow">
          <v-icon v-bind="attrs" v-on="on">mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <v-carousel-item v-for="(font, key) in fonts" :key="key">
        <v-sheet color="#ffffff" height="100%" tile>
          <v-row class="fill-height" align="center" justify="center">
            <div class="font-sign-wrapper">
              <svg
                :id="font.family"
                class="svg-item"
                xmlns="http://www.w3.org/2000/svg"
              >
                <text
                  x="50%"
                  y="80%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  :font-family="font.family"
                >
                  {{ name }}
                </text>
              </svg>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-divider></v-divider>
    <v-card-actions class="py-4 px-0">
      <v-btn depressed @click="onCancelClick">
        <i18n path="Common.Cancel" />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="primary"
        :disabled="signIsDisabled"
        @click="onSignClick"
      >
        <i18n v-if="type === 'initials'" path="Common.Submit" />
        <i18n v-else path="Common.Sign" />
      </v-btn>
    </v-card-actions>
    <canvas id="canvas-item" height="36px" width="200px" />
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    name: String,
    type: String
  },
  data: function() {
    return {
      fonts: [
        { family: "Alex Brush" },
        { family: "Allura" },
        { family: "Euphoria Script" },
        { family: "Parisienne" }
      ],
      model: 0,
      delimiters: true
    };
  },
  computed: {
    selectedFont() {
      return this.fonts[this.model].family;
    },
    signIsDisabled() {
      return !this.name.length > 0;
    }
  },
  methods: {
    onSignClick() {
      if (this.selectedFont) {
        const canvas = document.getElementById("canvas-item");
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = "#999999";
        const fontSize = 24;
        context.fillStyle = "#000000";
        context.font = `${fontSize}px ${this.selectedFont}`;
        const fontWidth = context.measureText(this.name).width;
        const horizontalPosition = (canvas.width - fontWidth) / 2;
        context.fillText(this.name, horizontalPosition, canvas.height - 15);

        const imgSource = { data: canvas.toDataURL() };
        this.$emit("onSave", imgSource);
      }
    },
    onCancelClick() {
      this.$emit("onCancel");
    },
    displayDelimiters() {
      this.delimiters = false;
    },
    hideDelimiters() {
      this.delimiters = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Euphoria+Script&family=Parisienne&display=swap");
.svg-item {
  height: 45px;
  border-bottom: #b2b2b2 2px solid;
  width: 100%;
}
.svg-item text {
  font-size: 25px;
}

.font-sign-wrapper {
  background-color: #ffffff;
  width: 50%;
  height: 70px;
  padding: 10px;
  padding-bottom: 5px;
  border: 1px solid #c1c1c1;
}

.v-carousel__controls__item.v-btn.v-btn--icon::before {
  opacity: 0;
}

.v-window__next,
.v-window__prev {
  background-color: transparent !important;
}

.v-btn--active > .v-btn__content .v-icon {
  color: #1976d2;
  opacity: 1 !important;
}

#carousel-arrow {
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  box-shadow: none;
}

#canvas-item {
  display: none;
}
</style>
