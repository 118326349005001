<template>
  <v-app-bar flat fixed height="50px" color="primary">
    <v-spacer></v-spacer>
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown && !initialDisplay"
      color="green accent-3"
      class="mr-4 white--text"
      :disabled="disableScroll"
      @click="scrollToSign"
    >
      <i18n v-if="scrollButtonText === 'start'" path="Common.Start" />
      <i18n v-else-if="scrollButtonText === 'next'" path="Common.Next" />
      <i18n v-else-if="scrollButtonText === 'sign'" path="Common.Sign" />
      <i18n v-else path="Common.Finish" />
    </v-btn>
    <LanguageSwitcher />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="declineSignClick">
          <v-list-item-title><i18n path="Common.Decline" /></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher.vue";
export default {
  components: { LanguageSwitcher },
  name: "Header",
  props: {
    imgLogoSrc: {
      type: String,
      required: false,
      default: "https://login.bitpod.io/auth/Content/BitPod-Logo.png"
    },
    initialDisplay: {
      type: Boolean,
      required: true
    },
    disableScroll: {
      type: Boolean,
      required: true
    },
    scrollButtonText: {
      type: String,
      required: true
    }
  },
  methods: {
    scrollToSign() {
      this.$emit("scrollToSign");
    },
    declineSignClick() {
      this.$emit("declineSignClick");
    }
  }
};
</script>