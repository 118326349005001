<template>
  <div data-iterator="fill" class="esignature-stamp-field sign-iterator">
    <v-dialog
      persistent
      scrollable
      content-class="slide-form"
      v-model="dialog"
      max-width="700px"
    >
      <v-card class="overflow-hidden pa-0">
        <v-card-title class="pt-1">
          <h2 class="black--text pt-4 pb-2 text-h5">
            <i18n path="Common.SubmitStamp" />
          </h2>
        </v-card-title>
        <v-card-text class="px-6">
          <v-col cols="12" class="px-0">
            <v-col cols="12" class="body-1 py-2 px-0 grey--text text--darken-2">
              <input
                ref="file"
                type="file"
                accept="image/*"
                style="display: none"
                @change="inputFileChange"
              />
              <v-card
                v-if="!stampImage"
                :class="{
                  'grey lighten-2': dragover,
                  'py-2 esignature-stamp-upload': true,
                }"
                @click="!stampImage && $refs.file.click()"
                @drop.prevent="onFileDrop"
                @dragover.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
              >
                <v-card-text>
                  <v-row
                    class="d-flex flex-column"
                    dense
                    align="center"
                    justify="center"
                  >
                    <v-icon
                      :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                      size="60"
                    >
                      mdi-cloud-upload
                    </v-icon>
                    <p>
                      <i18n path="Common.DropImageFile" />
                    </p>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row v-else>
                <v-chip
                  outlined
                  large
                  class="ma-2 esignature-stamp-upload-selected"
                  close
                  @click:close="clearDocument"
                >
                  <v-avatar left>
                    <v-icon>mdi-file</v-icon>
                  </v-avatar>
                  {{ stampImage.name }}
                  <span class="ml-3 text--secondary">
                    {{ formatBytes(stampImage.size) }}</span
                  >
                </v-chip>
              </v-row>
            </v-col>
          </v-col>
          <v-card-actions class="py-4 px-0">
            <v-btn depressed @click="onCancelClick">
              <i18n path="Common.Cancel" />
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              :disabled="disableStampButton"
              color="primary"
              @click="onUploadClick"
            >
              <i18n path="Common.SubmitStamp" />
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-menu v-if="signInfo.signStamp" absolute>
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
            <img
              :src="signInfo.signStamp"
              v-bind="{ ...attrs, ...attrsTooltip }"
              v-on="{ ...on, ...onTooltip }"
              class="stamp-display-panel"
            />
          </template>
          <span>
            <i18n path="Common.ClickToChange" />
          </span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item link @click="onClearClick">
          <v-list-item-title class="red--text">
            <i18n path="Common.Clear" />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          height="36px"
          width="170px"
          @click.stop="dialog = true"
          elevation="0"
          color="red lighten-4"
          v-bind="attrs"
          v-on="on"
        >
          <i18n path="Common.StampHere" />
          <v-icon dense right> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>
        <i18n path="Common.ClickToStamp" />
      </span>
    </v-tooltip>
  </div>
</template>

<script>
const uploadFileSizeLimit = 512000;

export default {
  name: "StampPicker",
  props: {
    signInfo: Object,
    id: String,
  },
  data() {
    return {
      stampImage: "",
      stampImageFinal: "",
      dialog: false,
      dragover: false,
      uploadButtonIsDisabled: false,
    };
  },
  computed: {
    idNum() {
      return parseInt(this.id);
    },
    disableStampButton() {
      if (!this.stampImage) return true;
      else if (this.stampImage.size >= uploadFileSizeLimit) {
        this.$parent.$parent.$emit(
          "onErrorDisplay",
          `File size is greater than ${this.formatBytes(uploadFileSizeLimit)}`
        );
        return true;
      }
      return false;
    },
  },
  methods: {
    onClearClick() {
      this.stampImageFinal = "";
      this.$parent.$parent.$emit("onSign", {
        id: this.idNum,
        signed: false,
      });
      const updatedSignInfo = { ...this.signInfo };
      this.stampImage = "";
      updatedSignInfo.signStamp = "";
      this.$parent.$parent.$emit("onSignInfoUpdate", updatedSignInfo);
    },
    onCancelClick() {
      this.dialog = false;
      this.stampImage = "";
    },
    onUploadClick() {
      this.stampImageFinal = this.stampImage;
      this.stampImage = "";
      this.dialog = false;
      try {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.stampImageFinal);
        fileReader.onloadend = () => {
          this.$parent.$parent.$emit("onSign", {
            id: this.idNum,
            signed: true,
          });
          const updatedSignInfo = { ...this.signInfo };
          updatedSignInfo.signStamp = fileReader.result;
          this.$parent.$parent.$emit("onSignInfoUpdate", updatedSignInfo);
        };
      } catch (err) {
        console.error(
          "Error encountered in ESignStampPicker while attempting to read an image file: ",
          err
        );
      }
    },
    onFileDrop(event) {
      this.dragover = false;
      if (
        event.dataTransfer.files.length === 1 &&
        event.dataTransfer.files[0].type === "images/*"
      )
        this.stampImage = event.dataTransfer.files[0];
    },
    inputFileChange(event) {
      const files = event.target.files;
      if (files.length === 1) this.stampImage = files[0];
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    clearDocument() {
      this.stampImage = null;
    },
  },
};
</script>

<style scoped>
.esignature-stamp-field {
  display: inline-block;
}
.esignature-stamp-upload {
  box-shadow: none !important;
  border: 1px dotted black !important;
}
.esignature-stamp-upload-selected {
  width: 100%;
  display: flex;
  justify-content: center;
}
.stamp-display-panel {
  height: 36px;
}
</style>
