<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(language, index) in languages"
        :key="index"
        @click="$i18n.locale = language.locale"
      >
        <v-list-item-title><i18n :path="language.caption" /></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      languages: [
        {
          locale: "en",
          caption: "Common.English"
        },
        {
          locale: "hi",
          caption: "Common.Hindi"
        },
        {
          locale: "fr",
          caption: "Common.French"
        },
        {
          locale: "he",
          caption: "Common.Hebrew"
        }
      ]
    };
  }
};
</script>