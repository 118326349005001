import en from './en-US.json';
import hi from './hi-IN.json';
import fr from './fr.json';
import he from './he.json';

export const checkDefaultLanguage =(languages)=> {
  let matched = null
  Object.keys(languages).forEach(lang => {
    if (lang === navigator.language) {
      matched = lang
    }
  })
  if (!matched) {
    Object.keys(languages).forEach(lang => {
      let languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  return matched
}

export const languages = {
  en: en,
  hi: hi,
  fr: fr,
  he: he,
}